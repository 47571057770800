import React from 'react';
import { Box, Stack, Text } from 'grommet';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container } from './imageStack.css';

const ImageStack = ({ image, textColor, textAlign, children }) => (
  <Container>
    <Box alignSelf="center" className="desktopView">
      <Stack alignSelf="center" anchor={textAlign}>
        <div style={{ width: '100vw', maxWidth: '1150px' }}>
          <Img
            fluid={image ? image.childImageSharp.fluid : {}}
            alt="Section Image"
          />
        </div>
        <Box width="medium" margin="medium">
          <Text size="small" color={textColor}>
            {children}
          </Text>
        </Box>
      </Stack>
    </Box>
    <Box alignSelf="center" className="mobileView">
      <div style={{ width: '100vw', maxWidth: '1000px' }}>
        <Img
          fluid={image ? image.childImageSharp.fluid : {}}
          alt="Section Image"
        />
      </div>
      <Box margin="medium" pad="medium" round="small" background="light-1">
        <Text size="small">{children}</Text>
      </Box>
    </Box>
  </Container>
);

ImageStack.propTypes = {
  image: PropTypes.object.isRequired,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Card = ({ image, alignImage, background, children }) => {
  if (alignImage == 'left') {
    return (
      <Box
        pad="medium"
        direction="row-responsive"
        background={background ? background : 'light-1'}
        width="xlarge"
        alignSelf="center"
      >
        <Box width="large" margin="medium" alignSelf="center">
          <Img
            fluid={image ? image.childImageSharp.fluid : {}}
            alt="Card Image"
          />
        </Box>
        <Box width="80%" align="center" alignSelf="center">
          {children}
        </Box>
      </Box>
    );
  } else if (alignImage == 'right') {
    return (
      <Box
        pad="medium"
        direction="row-responsive"
        background={background ? background : 'light-1'}
        width="xlarge"
        alignSelf="center"
      >
        <Box width="80%" align="center" alignSelf="center">
          {children}
        </Box>
        <Box width="large" margin="medium" alignSelf="center">
          <Img
            fluid={image ? image.childImageSharp.fluid : {}}
            alt="Card Image"
          />
        </Box>
      </Box>
    );
  }
};

Card.propTypes = {
  image: PropTypes.object.isRequired,
  alignImage: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export { ImageStack, Card };
