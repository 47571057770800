import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`“U.S. 3rd year medical students attempted direct ophthalmoscopy only 11% of the time in which an ocular fundus examination was clinically indicated.”`}</MDXTag></MDXTag>

export const _frontmatter = {};

  