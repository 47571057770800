import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Featured content and material:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`History of Ophthalmoscopy`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Examination Principles & Technique`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Instrument Use`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Retinal Feature Identification`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Pathology - Papilledema, Diabetic Retinopathy, Glaucoma, Choroidal Rupture, Macular Degeneration, plus more`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Self-Examinations`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Examinations`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  