import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OphthoSim™ Ophthalmoscopy Training & Simulation System is a hands-on simulation system that radically transforms how students learn to diagnose and treat eye pathologies. Its proprietary software and accompanying tools empower students to actively diagnose real-world eye conditions within a simulated environment`}</MDXTag></MDXTag>

export const _frontmatter = {};

  