import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 785px) {
    margin-bottom: 0;
    .desktopView {
      display: none;
    }
  }

  @media screen and (min-width: 786px) {
    margin-bottom: 0;
    .mobileView {
      display: none;
    }
  }
`;
