import React from 'react';
import { Box, Heading } from 'grommet';
import PropTypes from 'prop-types';

const SectionTitle = ({ title }) => (
  <Box
    alignSelf="center"
    alignContent="center"
    align="center"
    background="white"
    width="xlarge"
    pad="small"
  >
    <Heading
      level="1"
      size="small"
      responsive={true}
      alignSelf="center"
      color="neutral-3"
      textAlign="center"
    >
      {title}
    </Heading>
  </Box>
);

SectionTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionTitle;
