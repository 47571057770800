import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    OphthoSim  software:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`OphthoSim™ Content -  Includes training and testing modules to practice and evaluate ophthalmoscopy technique`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Database of 200 High Resolution Images - Supplemented with detailed text descriptions and some images are pre-annotated for normal landmarks and pathological features`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Landmarking Function - Allows instructor to highlight specific characteristics of pathologies in the region student is viewing to enhance student-instruction interactions`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Advanced Quizzes - Increases student capability with randomized realistic clinical scenarios that test both medical and patient interaction skills`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Easy-to-use Graphical User Interface - Improves viewer retention though immersive full-screen experience`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  