import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    OphthoSim includes:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Base Unit`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Eye Piece`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Instrumented Ophthalmoscope`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Control Box`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Software Package & Manual on USB Key`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Protective Hard Cases`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1-Year Warranty (Extended Warranties for a total of 2 or 3 years are available for purchase)`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  