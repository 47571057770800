import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import { ImageStack, Card } from 'components/pageCards';
import ProductIntro from 'components/productIntro';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import Img from 'gatsby-image';
import Accessories from '../../content/products/ophthosim/accessories.mdx';
import Branches from '../../content/products/ophthosim/branches.mdx';
import ContentAndMaterial from '../../content/products/ophthosim/contentAndMaterial.mdx';
import Includes from '../../content/products/ophthosim/includes.mdx';
import Software from '../../content/products/ophthosim/software.mdx';
import Features from '../../content/products/ophthosim/features.mdx';
import Intro from '../../content/products/ophthosim/intro.mdx';
import Intro2 from '../../content/products/ophthosim/intro2.mdx';

import Video from 'components/video';


const Ophthosim = ({ data }) => (
  <Layout>
    <Head pageTitle={data.ophthosimJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="OphthoSim™" />
      <Box width="xlarge" alignSelf="center">
      
      <Box margin={{ top: 'small', bottom: 'small' }}>
          <Video id="TANZ8DkA8_k" />
          </Box>
          
      
      
      
      
        <ProductIntro>
          <Intro />
        </ProductIntro>
        <RequestBar />
        <ImageStack
          image={data.ophthosimJson.pagePic1}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="xlarge">
            <Intro2 />
          </Text>
        </ImageStack>
        <ImageStack
          image={data.ophthosimJson.pagePic2}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="medium">
            <Features />
          </Text>
        </ImageStack>
        <Card image={data.ophthosimJson.pagePic5} alignImage="left">
          <Software />
        </Card>
        <ImageStack
          image={data.ophthosimJson.pagePic3}
          textColor="dark-1"
          textAlign="left"
        >
          <Text size="medium">
            <Branches />
          </Text>
        </ImageStack>
        <Box
          background="light-1"
          direction="row-responsive"
          justify="center"
          pad="medium"
        >
          <Box>
            <ContentAndMaterial />
          </Box>
          <Box>
            <Includes />
          </Box>
        </Box>
        <Box width="large" alignSelf="center">
          <Img
            fluid={
              data.ophthosimJson.pagePic4
                ? data.ophthosimJson.pagePic4.childImageSharp.fluid
                : {}
            }
            alt="OphthoSim"
          />
        </Box>
        <Box background="light-1" pad="large">
          <Accessories />
        </Box>
        <RequestBarExtended />
      </Box>
    </Box>
  </Layout>
);

Ophthosim.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Ophthosim;

export const query = graphql`
  query OphthosimQuery {
    ophthosimJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic3 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic4 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic5 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;