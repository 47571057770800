import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './video.css';

const Video = ({ id }) => (
  <Container>
    <div className="video-responsive">
      <iframe
        title="OtoSim Video"
        width="560"
        height="315"
        src={
          'https://www.youtube.com/embed/' +
          id +
          '?wmode=opaque&autoplay=1&showinfo=0&rel=0'
        }
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  </Container>
);

Video.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Video;
