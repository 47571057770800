import React from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';

const ProductIntro = ({ children }) => (
  <Box
    alignSelf="center"
    margin="small"
    pad="small"
    round="small"
    width="xlarge"
  >
    <Text size="large">{children}</Text>
  </Box>
);

ProductIntro.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductIntro;
