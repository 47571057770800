import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    OphthoSim key features:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`OphthoSim™ Ophthalmoscope -  Allows students to practice monocular vision and improve their ophthalmoscopic/funduscopic technique`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Tracked Movements of the Ophthalmoscope - Verifies student progress as the ophthalmoscope’s movements and orientation are tracked by the instructor to complete the learning feedback loop`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Realistic OphthoSim™ Eye Geometry - Replicates the magnification of lens in the human eye and different clinical conditions`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  