import React from 'react';
import { Box, Text } from 'grommet';
import PageContact from '../../../content/contact/pageContact.mdx';
import RouteButton from 'components/routeButton';
import { Link } from 'gatsby';

const RequestBar = () => (
  <Box alignSelf="center">
    <RouteButton to="/request-form" label="Request a Quote or Demo" />
    <Box alignSelf="center" margin="small">
      <Link
        to="/brochure-form"
        style={{ color: 'white', textDecoration: 'none' }}
      >
        <Text weight="bold" alignSelf="center" color="neutral-3">
          Download Brochure
        </Text>
      </Link>
    </Box>
  </Box>
);

const RequestBarExtended = () => (
  <Box>
    <RequestBar />
    <Text size="medium" alignSelf="center" textAlign="center">
      <PageContact />
    </Text>
  </Box>
);

export { RequestBar, RequestBarExtended };
