import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Accessories:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Educators Toolkit Software Upgrade:`}</MDXTag>{` Software add-on to OphthoSim™ for customized education sessions and mass training. Features enable instructors to:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">{`Upload and pre-annotate their own images`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Customize lecture slides`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Connect, lead and monitor a group of OphthoSim™ users to enable distance learning`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  