import React from 'react';
import { Box, Button } from 'grommet';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

const RouteButton = ({ to, label, color, alignPosition }) => (
  <Box margin="small" alignSelf={alignPosition ? alignPosition : 'center'}>
    <Button
      label={label}
      primary={true}
      color={color ? color : 'neutral-3'}
      onClick={() => {
        navigate(to);
      }}
    />
  </Box>
);

RouteButton.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  alignPosition: PropTypes.string,
};

export default RouteButton;
